import React from 'react'
import { titleCase } from 'title-case';
import { graphql } from 'gatsby'

import { Layout, PageCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'
import { slugToReferralMap } from '../data/referralInfo';

/**
* Tag page (/tag/:slug)
*
* Loads all pages for the requested tag incl. pagination.
*
*/
const PagesTag = ({ data, location, pageContext }) => {
    const tag = data.ghostTag
    const pages = data.allGhostPage.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout location={location}>
                <div className="container">
                    <header className="tag-header">
                        <h1>{tag.meta_title}</h1>
                        {tag.description ? <p>{tag.description}</p> : null }
                    </header>
                    <section className="post-feed">
                        {pages.map(({ node }) => {
                            let title = node.title;
                            if (tag.name === 'referrals') {
                                const referralNode = slugToReferralMap[node.slug];
                                if (referralNode.bonus) {
                                    title = titleCase(`${referralNode.name} - ${referralNode.bonus}`);
                                } else {
                                    title = referralNode.name;
                                }
                            }

                            return <PageCard key={node.id} post={node} title={title} />;
                        })}
                    </section>
                    <Pagination pageContext={pageContext} />
                </div>
            </Layout>
        </>
    )
}

export default PagesTag

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPage(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPageFields
                }
            }
        }
    }
`
